import React, { useState } from 'react';
import { Plus, Minus } from 'lucide-react';

interface FaqItem {
  question: string;
  answer: string;
}

const faqItems: FaqItem[] = [
  {
    question: "Сколько времени занимает строительство дома из СИП панелей?",
    answer: "Строительство дома из СИП панелей занимает от 2 до 3 месяцев. Это значительно быстрее традиционного строительства благодаря использованию готовых панелей и современной технологии монтажа. Сроки могут варьироваться в зависимости от размера дома и сложности проекта."
  },
  {
    question: "Какой срок службы у домов из СИП панелей?",
    answer: "При правильной эксплуатации и соблюдении технологии строительства, срок службы дома из СИП панелей составляет более 100 лет. Материалы не подвержены гниению, плесени и воздействию вредителей. Конструкция сохраняет свои технические характеристики на протяжении всего срока службы."
  },
  {
    question: "Насколько теплым будет дом из СИП панелей?",
    answer: "Дома из СИП панелей обладают отличными теплоизоляционными свойствами. Благодаря особой структуре панелей, теплопотери минимальны, что позволяет экономить до 40% на отоплении по сравнению с традиционными домами. Зимой дом хорошо держит тепло, а летом сохраняет прохладу."
  },
  {
    question: "Можно ли строить дом из СИП панелей зимой?",
    answer: "Да, строительство из СИП панелей возможно круглый год. Технология не требует использования мокрых процессов, поэтому низкие температуры не влияют на качество строительства. Это одно из главных преимуществ СИП технологии."
  },
  {
    question: "Насколько прочны дома из СИП панелей?",
    answer: "СИП дома обладают высокой прочностью и сейсмоустойчивостью до 9 баллов. Конструкция способна выдерживать значительные нагрузки благодаря особой структуре панелей и технологии соединения. Дома успешно эксплуатируются в различных климатических условиях."
  },
  {
    question: "Безопасны ли материалы, используемые в СИП панелях?",
    answer: "Все материалы, используемые в производстве СИП панелей, сертифицированы и соответствуют строгим стандартам безопасности. Панели не выделяют вредных веществ, не горючи (имеют класс горючести Г1) и безопасны для здоровья человека."
  },
  {
    question: "Какой фундамент нужен для дома из СИП панелей?",
    answer: "Благодаря легкому весу конструкции, для домов из СИП панелей подходит облегченный фундамент. Чаще всего используется свайно-винтовой или ленточный фундамент. Это позволяет существенно сэкономить на этапе закладки фундамента."
  },
  {
    question: "Как обстоят дела с вентиляцией в СИП доме?",
    answer: "В домах из СИП панелей необходима организация правильной вентиляции из-за высокой герметичности конструкции. Мы устанавливаем современные системы вентиляции, которые обеспечивают оптимальный воздухообмен и комфортный микроклимат в помещении."
  },
  {
    question: "Можно ли получить ипотеку на дом из СИП панелей?",
    answer: "Да, многие банки предоставляют ипотечные кредиты на дома из СИП панелей. Технология строительства официально признана и соответствует всем строительным нормам. Мы помогаем нашим клиентам в оформлении необходимой документации для получения ипотеки."
  },
  {
    question: "Какие отделочные материалы можно использовать для СИП дома?",
    answer: "Для внешней отделки дома из СИП панелей подходят практически любые материалы: сайдинг, штукатурка, камень, кирпич, термопанели и другие. Внутренняя отделка также не имеет ограничений и может выполняться любыми стандартными материалами."
  }
];

export default function HomeFaq() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFaq = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-secondary-900 mb-4">Часто Задаваемые Вопросы</h2>
          <p className="text-xl text-secondary-600 max-w-3xl mx-auto">
            Ответы на самые популярные вопросы о строительстве домов из СИП панелей
          </p>
        </div>

        <div className="max-w-4xl mx-auto">
          <div className="space-y-4">
            {faqItems.map((item, index) => (
              <div
                key={index}
                className="bg-white rounded-xl border border-gray-200 overflow-hidden transition-all duration-200 hover:shadow-md"
              >
                <button
                  className="w-full px-6 py-4 text-left flex items-center justify-between focus:outline-none"
                  onClick={() => toggleFaq(index)}
                >
                  <span className="text-lg font-medium text-gray-900">
                    {item.question}
                  </span>
                  {openIndex === index ? (
                    <Minus className="h-5 w-5 text-primary-600 flex-shrink-0" />
                  ) : (
                    <Plus className="h-5 w-5 text-primary-600 flex-shrink-0" />
                  )}
                </button>
                
                <div
                  className={`px-6 transition-all duration-200 ease-in-out ${
                    openIndex === index ? 'pb-4 max-h-96' : 'max-h-0 overflow-hidden'
                  }`}
                >
                  <p className="text-gray-600 leading-relaxed">
                    {item.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="text-center mt-12">
          <button
            onClick={() => {
              const phone = "77772282323";
              const message = "Здравствуйте! У меня есть вопрос о строительстве дома из СИП панелей.";
              window.open(`https://wa.me/${phone}?text=${encodeURIComponent(message)}`, '_blank');
            }}
            className="inline-flex items-center px-8 py-3 bg-primary-600 text-white rounded-xl hover:bg-primary-700 transition-colors shadow-lg shadow-primary-600/30"
          >
            Задать свой вопрос
          </button>
        </div>
      </div>
    </section>
  );
}