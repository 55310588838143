import React, { useState } from 'react';
import { Plus, Minus } from 'lucide-react';

interface FaqItem {
  question: string;
  answer: string;
}

const faqItems: FaqItem[] = [
  {
    question: "Сколько времени занимает установка модульного дома?",
    answer: "Установка модульного дома занимает 1-2 дня. Полная готовность к проживанию достигается через 14 дней после начала работ. Это время включает подключение всех коммуникаций и финальную отделку."
  },
  {
    question: "Какой срок службы у модульного дома?",
    answer: "При правильной эксплуатации срок службы модульного дома составляет более 50 лет. Все конструкции изготавливаются из высококачественных материалов с защитой от коррозии и других внешних воздействий."
  },
  {
    question: "Нужно ли разрешение на установку модульного дома?",
    answer: "Да, для установки модульного дома требуется разрешительная документация. Мы помогаем нашим клиентам в оформлении всех необходимых документов и получении разрешений на строительство."
  },
  {
    question: "Какой фундамент нужен для модульного дома?",
    answer: "Для модульного дома подходит облегченный фундамент - винтовые сваи или ленточный фундамент небольшой глубины. Тип фундамента зависит от грунта и размера дома. Мы проводим исследование участка и рекомендуем оптимальный вариант."
  },
  {
    question: "Можно ли перевозить модульный дом на другое место?",
    answer: "Да, это одно из главных преимуществ модульных домов. Конструкция может быть демонтирована и перевезена на новое место без потери качества. Процесс занимает 1-2 дня и требует специальной техники."
  },
  {
    question: "Как обстоят дела с теплоизоляцией?",
    answer: "Модульные дома имеют отличную теплоизоляцию. Мы используем современные теплоизоляционные материалы, которые обеспечивают комфортную температуру зимой и прохладу летом. Стены имеют высокий коэффициент теплосбережения."
  },
  {
    question: "Какая отделка входит в стоимость?",
    answer: "В базовую комплектацию входит полная внутренняя отделка стен, потолка и пола, установка сантехники, электрики и систем отопления. Возможны различные варианты отделки на выбор."
  },
  {
    question: "Как производится оплата за модульный дом?",
    answer: "Оплата производится в несколько этапов: предоплата при заказе (30%), оплата после готовности модулей (40%) и окончательный расчет после установки (30%). Возможна покупка в рассрочку или кредит."
  },
  {
    question: "Какие коммуникации можно подключить к модульному дому?",
    answer: "Модульный дом может быть подключен ко всем необходимым коммуникациям: электричество, водоснабжение, канализация, отопление, интернет. Также возможна установка автономных систем жизнеобеспечения."
  },
  {
    question: "Предоставляется ли гарантия на модульный дом?",
    answer: "Да, мы предоставляем гарантию 3 года на конструкцию дома и 1 год на внутреннюю отделку и инженерные системы. В течение гарантийного срока все обнаруженные недостатки устраняются бесплатно."
  }
];

export default function ModularFaq() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const toggleFaq = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="mt-24">
      <div className="max-w-3xl mx-auto bg-white rounded-2xl shadow-xl p-8 border border-gray-200">
        <h2 className="text-2xl font-bold text-center text-gray-900 mb-8">
          Часто Задаваемые Вопросы
        </h2>
        
        <div className="space-y-4">
          {faqItems.map((item, index) => (
            <div
              key={index}
              className="border-b border-gray-200 last:border-0"
            >
              <button
                className="w-full py-4 flex items-center justify-between text-left focus:outline-none group"
                onClick={() => toggleFaq(index)}
              >
                <span className="text-lg font-medium text-gray-900 group-hover:text-primary-600 transition-colors">
                  {item.question}
                </span>
                {openIndex === index ? (
                  <Minus className="h-5 w-5 text-primary-600" />
                ) : (
                  <Plus className="h-5 w-5 text-primary-600" />
                )}
              </button>
              
              <div
                className={`overflow-hidden transition-all duration-200 ${
                  openIndex === index ? 'max-h-96 pb-4' : 'max-h-0'
                }`}
              >
                <p className="text-gray-600 leading-relaxed">
                  {item.answer}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}